import React, { Component } from 'react';
import './home.css';

class Home extends Component {
    constructor(props) {
        super(props)

        this.openGifts = this.openGifts.bind(this)
        this.openAccess = this.openAccess.bind(this)
        this.openPepe = this.openPepe.bind(this)

        this.state = {
            giftsIsOpen: false,
            accessIsOpen: false,
            pepeIsOpen: false
        }
    }

    openGifts() {
        this.setState({
            giftsIsOpen: !this.state.giftsIsOpen,
        })
    }
    openAccess() {
        this.setState({
            accessIsOpen: !this.state.accessIsOpen,
        })
    }
    openPepe() {
        this.setState({
            pepeIsOpen: !this.state.pepeIsOpen,
        })
    }

    render() {
        return (
            <div className="view-wrapper">
                <div className="column align-center">
                    <h1>pēpē pāti</h1>
                    <h3 style={{ color: "grey" }}>a party to shower your friends in babies</h3>
                </div>
                <div className="column align-center">
                    <div className="instructions">
                        <div className="instruction-row">
                            <h3 style={{ marginBottom: "20px" }}>Come along to a baby celebration</h3>
                        </div>
                        <div className="instruction-row">
                            <p>with Maddy and James</p>
                        </div>
                        <div className="instruction-row">
                            <p style={{ marginBottom: "0" }}>Sunday, 19th September at 2pm</p>
                        </div>
                        <div className="instruction-row">
                            <p>(Confirmed now we're in Level 2)</p>
                        </div>
                        <div className="instruction-row">
                            <p style={{ color: "#585864" }}>Potluck</p>
                        </div>
                        <div className="instruction-row">
                            <p><a href="mailto:pepepati@maddyking44.com?subject=Kei%20whea%20te%20pēpē%20pāti?%20&body=Hi%20Maddy,%20What%20is%20the%20address%20for%20the%20pēpē%20pāti?%20" target="_blank" rel="nofollower noreferrer">What's the address?</a></p>
                        </div>
                    </div>
                    <div className={this.state.giftsIsOpen ? "open-instructions" : "closed-instructions"} >
                        <div className="instruction-row">
                            <h3><button className="text-btn" type="button" onClick={() => this.openGifts()}>Kindness</button>
                                <button className="play-btn" type="button" style={{ display: this.state.giftsIsOpen ? "inline" : "none" }} onClick={() => this.openGifts()}>{"v"}</button>
                                <button className="play-btn" type="button" style={{ display: this.state.giftsIsOpen ? "none" : "inline" }} onClick={() => this.openGifts()}>{">"}</button>
                            </h3>
                        </div>
                        <div className="gifts-section" style={{ fontWeight: 100, display: this.state.giftsIsOpen ? "block" : "none" }}>
                            <div className="instruction-row" style={{ textAlign: "center" }}>
                                <p>Traditionally, pre-baby parties weren't consumer-driven. <br />They were about offering support to would-be parents <br />by passing on wisdom and learnings through the generations </p>
                            </div>
                            <div className="instruction-row">
                                <p>So in that spirit, we have a challenge for you!</p>
                            </div>
                            <div className="instruction-row">
                                <p>We'd like some advice</p>
                            </div>
                            <div className="instruction-row" style={{ textAlign: "center" }}>
                                <p>Please ask your parents, grandparents, aunts, uncles and friends  <br />who have raised youngsters for a few pieces of advice <br /> that they'd want others to know</p>
                            </div>
                            <div className="instruction-row" style={{ textAlign: "center" }}>
                                <p>Write each piece of guidance on a scrap of paper, and bring along with you</p>
                            </div>
                            <div className="instruction-row">
                                <p>We'll put it together and share at the fiesta</p>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.accessIsOpen ? "open-instructions" : "closed-instructions"}>
                        <div className="instruction-row">
                            <h3><button className="text-btn" type="button" onClick={() => this.openAccess()}>Access</button>
                                <button className="play-btn" type="button" style={{ display: this.state.accessIsOpen ? "inline" : "none" }} onClick={() => this.openAccess()}>{"v"}</button>
                                <button className="play-btn" type="button" style={{ display: this.state.accessIsOpen ? "none" : "inline" }} onClick={() => this.openAccess()}>{">"}</button>
                            </h3>
                        </div>
                        <div className="access-section" style={{ fontWeight: 100, display: this.state.accessIsOpen ? "block" : "none" }}>
                            <div className="instruction-row" style={{ textAlign: "center" }}>
                                <p>Park on the street at the bottom of the driveway to number 52 and walk up</p>
                            </div>
                            <div className="instruction-row">
                                <p>We're the last house on the driveway</p>
                            </div>
                            <div className="instruction-row">
                                <p style={{ textAlign: "center" }}>Or take the train to Plimmerton<br /> Let us know and we'll come pick you up from the station</p>
                            </div>
                            <div className="instruction-row">
                                <p>Be aware: Lots of stairs</p>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.accessIsOpen ? "open-instructions" : "closed-instructions"}>
                        <div className="instruction-row">
                            <h3><button className="text-btn" type="button" onClick={() => this.openPepe()}>About the pēpē</button>
                                <button className="play-btn" type="button" style={{ display: this.state.pepeIsOpen ? "inline" : "none" }} onClick={() => this.openPepe()}>{"v"}</button>
                                <button className="play-btn" type="button" style={{ display: this.state.pepeIsOpen ? "none" : "inline" }} onClick={() => this.openPepe()}>{">"}</button>
                            </h3>
                        </div>
                        <div className="pepe-section" style={{ display: this.state.pepeIsOpen ? "block" : "none" }}>
                            <div className="instruction-row">
                                <p>Expected:  <span className="about">Mid-November</span></p>
                            </div>
                            <div className="instruction-row">
                                <p>Likes:  <span className="about">Cooked banana, nature, listening to ukelele</span></p>
                            </div>
                            <div className="instruction-row">
                                <p>Dislikes:  <span className="about">Licorice, the gender binary, jogging</span></p>
                            </div>
                            <div className="instruction-row">
                                <p>Name, Sex, Gender:  <span className="about">Unknown</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="instructions">
                        <div className="instruction-row">
                            <h3 style={{ marginBottom: "15px" }}>Yay! See you there!</h3>
                        </div>
                        <div className="instruction-row">
                            <p>Friends & whānau welcome</p>
                        </div>
                        <div className="instruction-row" style={{ textAlign: "center" }}>
                            <p>Any questions? Contact the amazing Harriet<br /><a href="tel:64275135029">0275135029</a></p>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


export default Home